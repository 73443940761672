export const logos = {
    Twitter: require('../assets/images/platforms/twitter-logo.png'),
    YouTube: require('../assets/images/platforms/youtube-logo.png'),
    LinkedIn: require('../assets/images/platforms/linkedin-logo.png'),
    Facebook: require('../assets/images/platforms/facebook-logo.png'),
    Google: require('../assets/images/platforms/google-logo.png'),
    Instagram: require('../assets/images/platforms/instagram-logo.png'),
    Reddit: require('../assets/images/platforms/reddit-logo.png'),
    Vimeo: require('../assets/images/platforms/vimeo-log.png'),
    Pinterest: require('../assets/images/platforms/pinterest-log.png'),
    Medium: require('../assets/images/platforms/medium-logo.png'),
    Tumblr: require('../assets/images/platforms/tumblr-logo.png'),
    SoundCloud: require('../assets/images/platforms/soundcloud-logo.png'),
    Shazam: require('../assets/images/platforms/shazam-logo.png'),
    Spotify: require('../assets/images/platforms/spotify-logo.png'),
    Steemit: require('../assets/images/platforms/steemit-logo.png'),
    'Google News': require('../assets/images/platforms/google-news-logo.png'),
}
