import { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { AuthContext } from '../../context/auth-context'
import Loader from '../../layouts/components/Loader'
import { OAuth2Context } from "../../context/oauth2-context";


export const OAUTH_LOGIN_CALLBACK_URL = `${window.location.origin}/oauth/login/callback`

export const OAUTH_LOGIN_PROVIDERS = {
  google: {
    authorizeUrl: "https://accounts.google.com/o/oauth2/v2/auth",
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    scope: "openid email profile",
    name: "google",
  },
  facebook: {
    authorizeUrl: "https://www.facebook.com/v22.0/dialog/oauth",
    clientId: process.env.REACT_APP_META_LOGIN_CLIENT_ID,
    scope: "public_profile,email",
    name: "facebook",
  },
  linkedin: {
    authorizeUrl: "https://www.linkedin.com/oauth/v2/authorization",
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    scope: "openid profile email",
    name: "linkedin",
  }
}

const LOGIN_WITH_OAUTH = gql`
    mutation LoginWithOAuth($oauthInput: OAuthLoginInput!) {
        loginWithOAuth(oauthInput: $oauthInput) {
            token
        }
    }
`

const OAuthLoginCallback = () => {
  const navigate = useNavigate()
  const { login } = useContext(AuthContext)
  const context = useContext(OAuth2Context)
  const [ code, setCode ] = useState(null)

  const [ loginWithOAuth ] = useMutation(LOGIN_WITH_OAUTH, {
    onError({ graphQLErrors }) {
      console.error(graphQLErrors)
      context.removeState()
      navigate('/login', {
        state: {
          notification: {
            status: 'error',
            message: graphQLErrors[0]?.message || 'OAuth login failed'
          }
        }
      })
    },
    onCompleted: ({ loginWithOAuth }) => {
      context.removeState()
      login(loginWithOAuth)
      navigate('/')
    },
    variables: {
      oauthInput: {
        code,
        redirectUri: `${document.location.origin}/oauth/login/callback`,
        context: 'admin',
        platform: context.platform,
      }
    }
  })

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get('code')
    const error = queryParams.get('error')
    const returnedState = queryParams.get('state')

    if (!code || error || context.state == null || returnedState !== context.state) {
      console.log(`OAuth callback Failed for params: ${context.state} === ${returnedState}`)
      context.removeState()
      navigate('/login', {
        state: {
          notification: {
            status: 'error',
            message: error || 'OAuth login failed or invalid state'
          }
        }
      })
      return
    }

    setCode(code)
  }, [ context, navigate ])

  useEffect(() => {
    if (code) {
      loginWithOAuth()
    }
  }, [ code ])

  return <Loader/>
}

export default OAuthLoginCallback